@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: var(--font-notojp), sans-serif;
}

.item-detail-slide {
  .splide__slide {
    background-size: contain !important;
  }
}

.item-thumbnail-slide {
  .splide__slide {
    background-size: cover !important;
  }
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance: textfield !important;
}
